body {
    height: 100%;
    /*
    height: 300vh
    background-repeat: no repeat
    margin: 0 auto;
    padding: 0;

    */
     background-image: url(../img/lunar-eclipse-962804.jpg);
     background-repeat: repeat;
    background-size: cover;


  
}

/* Nav */


#navhome a{
  color: white;

}

#navbarNav ul li a{
  color: white;
}



#navbarNav ul li{
    cursor: pointer;
    position: relative;
    margin: 0 1em;
    padding: 0.5em 0.5em;
    transition: all 0.2s;
    transition-delay: 0.2s;
}
#navbarNav ul li::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #e59e64;
    transition: all  0.2s;
}
#navbarNav ul li:hover {
    background: linear-gradient(#2187e7 20%, #000000 50%)
;
}

#navbarNav ul li:hover::before {
    bottom: 56px;
    left: 0;
    background: linear-gradient(#e59e64 20%, #000000 50%, #DD2A7B 75%, #8134AF 90%, #515BD4)
;
}


.navbar-toggler-icon{
    background-color: #2187e7;
}





/* FIN NAV */

/* PAGE HOME (ACCEUIL) */
h1 {
    text-align: center;
    color:#fff;
    padding-top:10em;
  }
h1 a{
    color: white;

}
.neon {
    color: #fff;
    text-shadow:
            0 0 5px #fff,
            0 0 10px #fff,
            0 0 20px #fff,
            0 0 40px #0088cc,
            0 0 80px #0088cc,
            0 0 90px #0088cc,
            0 0 100px #0088cc,
            0 0 150px #0088cc;
}

.slider-thumb::before {
	position: absolute;
	content: "";
    left: 10%; /* mettre les media querie a  10% pour toutes les autres platform*/
	top: 20%;
	width: 750px;
	height: 650px;
    background: linear-gradient(#2187e7 20%, #000000 50%, #2187e7 50%, #000000 10%)
;
	border-radius: 62% 57% 82% 35% / 65% 55% 80% 66%;
	will-change: border-radius, transform, opacity;
	animation: sliderShape 5s linear infinite;
	display: block;
	z-index: -1;
	-webkit-animation: sliderShape 5s linear infinite;
}
@keyframes sliderShape{
  0%,100%{
  border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    transform: translate3d(0,0,0) rotateZ(0.01deg);
  }
  34%{
      border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
    transform:  translate3d(0,5px,0) rotateZ(0.01deg);
  }
  50%{
    transform: translate3d(0,0,0) rotateZ(0.01deg);
  }
  67%{
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60% ;
    transform: translate3d(0,-3px,0) rotateZ(0.01deg);
  }

}

/* FIN HOME */

/* A PROPOS */

.apropos{
    margin-top: 200px;
    margin-left: 100px;

}

#apropos_text_couleur{
    color: #2187e7;
    box-shadow: 0px 3px 20px #e59e64;
    text-shadow: 0px 3px 6px red;
}


.card{
    margin-left: 150px;
}


/* FIN A PROPOS */



/* ICON */

.social{
    width: 100%;
    height: auto;
    text-align: center;
}
.social a{
    display: inline-block;
    padding: 5px;

}
.social a i{
    color: white;
    width: 55px;
    height: 55px;
    font-size: 25px;
    line-height: 50px!important;
    border: 3px solid #e59e64;
    opacity: .8;
    transition-property: transform;
    -moz-transition-property: -moz-transform;
    -webkit-transition-property: -webkit-transform;
    transition: all 0.6s cubic-bezier(0.88, -0.48, 0.38, 1.85);
    border-radius: 50%;

}
.social a i:hover{
    opacity: 1;
    border-radius: 20%;

}
.social a:hover i.fa-linkedin-in{
    transition:  .6s ease-in;
    background: #0088cc;
    color: white;
    border: 3px solid #00aaff;

    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
}
.social a:hover i.fa-github{
    transition:  .6s ease-in;
    background: #D44638;
    color:white;
    border: 3px solid #fd8e8e;
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
}
.social a:hover i.fa-download{
    transition:  .6s ease-in;
    background: linear-gradient(#F58529 20%, #FEDA77 50%, #DD2A7B 75%, #8134AF 90%, #515BD4)
;
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    border: 3px solid #fd8e8e;
    color:white;

}

/* FIN ICON */

/* FORMATION */


.text {
    font-size: 3rem;
    color: #e59e64;
    text-align: center;
    text-shadow: 0px 3px 6px red;
}

.hover-filter-effects {
    background-color: #2187e7;
    height: 400px;
    max-width: 800px;
    margin: 5% auto;
    -webkit-filter: hue-rotate(50deg);
    filter: hue-rotate(50deg);
    box-shadow: 0px 3px 20px red;
    -webkit-transition: -webkit-filter 0.3s ease-in-out;
    transition: -webkit-filter 0.3s ease-in-out;
    transition: filter 0.3s ease-in-out;
    transition: filter 0.3s ease-in-out, -webkit-filter 0.3s ease-in-out;
}
.hover-filter-effects:hover {
    -webkit-filter: none;
    filter: none;
}

/* FIN FORMATION */

/* CONTACT */



.text-center{
 color: #2187e7;
    text-shadow: 0px 3px 6px red;
}


/* FIN CONTACT */

/* COMPETENCES */

.competencesbar {margin-top: 20px;width: 50%;}
.progress {
    height: 30px;
    border-radius: 50px;
    background-color: #474545;
    margin-bottom: 18px;
}
.progress-bar {
    background: linear-gradient(#0088cc 20%, #000000 50%, #DD2A7B 75%)
;

}
.progress-bar span {
    font-size: 15px;
    float: right;
    margin-top: 5px;
    margin-right: 5px;
}
.title {
    color: #fff;
    margin-bottom: 10px;
    display: inline-block;
    padding: 10px;
    background-color: #474545;
    border-radius: 50px;
    margin-left: 5px;
}

/* FIN COMPETENCES */




/* MENTIONS */
.mentionscookie{
    margin-top: 170px;
}


/* FIN MENTIONS */

/* PROJET */
.appear{
    opacity:0;
    position: relative;
    top: 50px;
}
